@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: inherit,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*{
  cursor: url(./images/circle.png) 16 16 ,auto;

}


@font-face {
  font-family: spaceman;
  src: url(../public/fonts/space.TTF);
}
@font-face {
  font-family: gtek;
  src: url(../public/fonts/gtek.ttf);
}
@font-face {
  font-family: spaceage;
  src: url(../public/fonts/spaceage.ttf);
}
@font-face {
  font-family: spacereg;
  src: url(../public/fonts/spacereg.otf);
}
::-webkit-scrollbar{
  display: none;
}
/* home page design */
.home{
  color: white;
  background: black;
  background-image: url(./images/heropage3.png);
  
  background-position: center;
  background-size: cover;
  height: 100vh;
}
